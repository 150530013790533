export default {
    toolbar: {
        items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo',
            'insertTable',
            'fileUploadInline'
        ]
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    heading: {
        options: [
            { 
                model: 'paragraph', 
                title: 'Paragraph', 
                class: 'ck-heading_paragraph' 
            },
            { 
                model: 'heading1', 
                view: 'h1', 
                title: 'Heading 1', 
                class: 'ck-heading_heading1' 
            },
            { 
                model: 'heading2', 
                view: 'h2', 
                title: 'Heading 2', 
                class: 'ck-heading_heading2' 
            },
            { 
                model: 'heading3', 
                view: 'h3', 
                title: 'Heading 3', 
                class: 'ck-heading_heading3' 
            },
            {
                model: 'headingTwoBlue',
                view: {
                    name: 'h2',
                    classes: 'headingBlue'
                },
                title: 'Heading 2 (blue)',
                converterPriority: 'high'
            },
            {
                model: 'headingTwoLightBlue',
                view: {
                    name: 'h2',
                    classes: 'headingLightBlue'
                },
                title: 'Heading 2 (light blue)',
                converterPriority: 'high'
            },
            {
                model: 'headingTwoOrange',
                view: {
                    name: 'h2',
                    classes: 'headingOrange'
                },
                title: 'Heading 2 (orange)',
                converterPriority: 'high'
            },
            {
                model: 'headingTwoLightOrange',
                view: {
                    name: 'h2',
                    classes: 'headingLightOrange'
                },
                title: 'Heading 2 (light orange)',
                converterPriority: 'high'
            },
            {
                model: 'headingThreeBlue',
                view: {
                    name: 'h3',
                    classes: 'headingBlue'
                },
                title: 'Heading 3 (blue)',
                converterPriority: 'high'
            },
            {
                model: 'headingThreeLightBlue',
                view: {
                    name: 'h3',
                    classes: 'headingLightBlue'
                },
                title: 'Heading 3 (light blue)',
                converterPriority: 'high'
            },
            {
                model: 'headingThreeOrange',
                view: {
                    name: 'h3',
                    classes: 'headingOrange'
                },
                title: 'Heading 3 (orange)',
                converterPriority: 'high'
            },
            {
                model: 'headingThreeLightOrange',
                view: {
                    name: 'h3',
                    classes: 'headingLightOrange'
                },
                title: 'Heading 3 (light orange)',
                converterPriority: 'high'
            },
            {
                model: 'ctaWithArrow',
                view: {
                    name: 'p',
                    classes: 'cta'
                },
                title: 'CTA With Arrow Style',
                converterPriority: 'high'
            }
        ]
    } 
}
